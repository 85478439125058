// Import Angular and related items
import { Routes } from '@angular/router';

// Import Guards
import { canActivateAuthGuard, canMatchAuthGuard } from '@guards/auth.guard';
import { canMatchAdminGuard } from '@guards/admin.guard';
import { canMatchAlliedUniversalGuard } from '@guards/allied-universal.guard';
import { canMatchFulfillmentGuard } from '@guards/fulfillment.guard';
import { canMatchMobilityGuard } from '@guards/mobility.guard';
import { canMatchWirelineGuard } from '@guards/wireline.guard';
import { canMatchProcurementGuard } from '@guards/procurement.guard';
import { canMatchSteveStuffGuard } from '@guards/steve-stuff.guard';
import { canMatchSysAdminGuard } from '@guards/sysadmin.guard';

export const routes: Routes = [
    {
      path: 'home',
      loadComponent: () => import('./home/home.component').then(h => h.HomeComponent),
      canActivate: [
        canActivateAuthGuard
      ]
    },
    {
      path: 'feedback',
      loadComponent: () => import('./components/feedback/feedback.component').then(f => f.FeedbackComponent),
    },
    {
      path: 'wireline',
      loadChildren: () => import('@wireline/wireline.module').then(w => w.WirelineModule),
      canMatch: [
        canMatchAuthGuard,
        canMatchWirelineGuard
      ]
    },
    {
      path: 'fulfillment',
      loadChildren: () => import('@fulfillment/fulfillment.module').then(f => f.FulfillmentModule),
      canMatch: [
        canMatchAuthGuard,
        canMatchFulfillmentGuard
      ]
    },
    {
      path: 'mobility',
       loadChildren: () => import('@mobility/mobility.module').then(m => m.MobilityModule),
       canMatch: [
        canMatchAuthGuard,
        canMatchMobilityGuard
      ]
    },
    {
      path: 'steves-stuff',
      loadChildren: () => import('@stevesStuff/steves-stuff.module').then(ss => ss.StevesStuffModule),
      canMatch: [
        canMatchAuthGuard,
        canMatchSteveStuffGuard
      ]
    },
    {
      path: 'sysadmin',
      loadChildren: () => import('@sysadmin/sysadmin.module').then(sa => sa.SysAdminModule),
      canMatch: [
        canMatchAuthGuard,
        canMatchSysAdminGuard
      ]
    },
    {
      path: 'admin',
      loadChildren: () => import('@admin/admin.module').then(a => a.AdminModule),
      canMatch: [
        canMatchAuthGuard,
        canMatchAdminGuard
      ]
    },
    {
      path: 'alliedUniversal',
      loadChildren: () => import('@alliedUniversal/allied-universal.module').then(al => al.AlliedUniversalModule),
      canMatch: [
        canMatchAuthGuard,
        canMatchAlliedUniversalGuard
      ]
    },
    {
      path: 'procurement',
      loadChildren: () => import('@procurement/procurement.module').then(m => m.ProcurementModule),
      canMatch: [
        canMatchAuthGuard,
        canMatchProcurementGuard
      ]
    },
    {
      path: 'login',
      loadComponent: () => import('./components/login/login.component').then(l => l.LoginComponent),
    },
    {
      path: '',
      redirectTo: 'home',
      pathMatch: 'full'
    },
    {
      path: '404',
      loadComponent: () => import('./components/not-found/not-found.component').then(f => f.NotFoundComponent),
    },
    {
      path: 'accessForbidden',
      loadComponent: () => import('./components/access-forbidden/access-forbidden.component').then(f => f.AccessForbiddenComponent),
    },
    {
      path: '**', redirectTo: '/404'
    }
];