export const environment = {
    debug: false,
    production: false,
    apiRoot: 'https://api.prestotech.it',
    commonApiRootUrl: 'https://common.api.prestotech.it',
    fulfillmentApiRootUrl: 'https://fulfillment.api.prestotech.it',
    mobilityApiRootUrl: 'https://mobility.api.prestotech.it',
    podApiRootUrl: 'https://pod.api.prestotech.it',
    sysadminApiRootUrl: 'https://sysadmin.api.prestotech.it',
    wirelineApiRootUrl: 'https://wireline.api.prestotech.it',
    name: 'qa'
};