<mat-toolbar color="primary" class="pt-toolbar mat-elevation-z6">
    <mat-toolbar-row>
        <div *ngIf="authService?.isLoggedIn">
            <a href="/" style="text-decoration-line: none" title="Back to Homepage">
                <div>
                    <span class="app-nav-bar">{{title}} </span>
                    <span class="projectVersion">(Version: {{projectVersion}})</span>
                </div>
            </a>
        </div>
        <span style="flex: 1 1 auto;">
            <div class="environmentMessageContainer" *ngIf="environmentName !== 'production'">
                <span class="environmentMessage">{{environmentMessage}}</span>
            </div>
        </span>
        <div style="margin-right: 20px;">
            <mat-icon class="example-icon" style="margin-right:15px;" title="Click to open feedback" (click)="feedback()">feedback</mat-icon>
            
            <div *ngIf="authService?.isLoggedIn" class="icons">
                <a cdk-overlay-origin #trigger="cdkOverlayOrigin" (click)="openApplicationAreaOverlay()">
                    <mat-icon class="example-icon" style="margin-right:15px;" title="Click to open a specific module">apps</mat-icon>
                </a>
                <a>
                    <mat-icon class="example-icon" style="margin-right:15px;" title="Back to Homepage" (click)="goHome()">home</mat-icon>
                </a>
            </div>
        </div>
        <div *ngIf="authService?.isLoggedIn">
            <span>{{authService.CurrentUser.firstName}} {{authService.CurrentUser.lastName}}</span>

            <button mat-icon-button [matMenuTriggerFor]="userMenu" aria-label="User Menu" class="va-middle">
                <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #userMenu="matMenu">
                <ng-template matMenuContent>
                    <button mat-menu-item (click)="openChangePasswordDialog()">
                        <mat-icon>vpn_key</mat-icon>
                        <span>Change Password</span>
                    </button>
                    <button mat-menu-item (click)="signOut()">
                        <mat-icon>exit_to_app</mat-icon>
                        <span>Sign Out</span>
                    </button>
                </ng-template>
            </mat-menu>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav-container class="main-container">
    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>