// Import Angular and related items
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, CanActivateFn, CanMatchFn } from '@angular/router';

// Import services
import { AuthService } from '@core/services/auth.service';

export const canActivateWirelineGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const url: string = state.url;
  return checkAccess(url);
}

export const canMatchWirelineGuard: CanMatchFn = (route: Route) => {
  const url: string = route.path;
  return checkAccess(url);
}

const checkAccess = (url: string): boolean => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.CurrentUser.userRoles.some(role => role.roleCode === 'wireln')) {
    return true;
  }

  authService.redirectUrl = url;

  router.navigate(['accessForbidden']);
  return false;
}