<div class="app-icon-group-list">
    <h2 class="f-color-blue">Goliath Modules</h2>
    <div class="app-icon-group-list-inner">
        <ul>
            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="center center" style="place-content: stretch right !important;">                
                <li *ngFor="let icon of userIcons" class="nav-card" fxFlex="0 1 calc(10% - 15px)">
                    <ng-container>
                        <mat-card appearance="raised" matTooltip="{{icon.featureName}}" matTooltipPosition="below" class="iconCard" [ngStyle]="{color: icon.textColor, 'background-color': icon.backgroundColor}" (click)="navigateToApplicationArea(icon.name)">
                            <mat-card-content>
                                <mat-icon class="icon-group-link">{{icon.matIconName}}</mat-icon>
                            </mat-card-content>
                        </mat-card>
                    </ng-container>                    
                </li>
            </div>
        </ul>
    </div>
</div>