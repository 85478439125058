// Import Angular and related items
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { ToastrModule } from 'ngx-toastr';

// Import routes
import { routes } from './app.routes';

// Import providers
import { AuthorizationInterceptor } from './interceptors/auth-interceptor';
import { AuthorizationInterceptorFunctional } from './interceptors/auth.interceptor';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        importProvidersFrom(
            BrowserModule,
            CoreModule,
            MatNativeDateModule,
            ToastrModule.forRoot({
                newestOnTop: false
            })
        ),
        provideAnimations(),
        //provideHttpClient(withInterceptors([AuthorizationInterceptorFunctional]))
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationInterceptor,
            multi: true
        }
    ]
};