// Inject Angular and related items
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError, share } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

// Import models
import { User } from '@core/models/user';
import { UserLogin } from '@core/models/user-login';
import { UserLoginResult } from '@core/models/user-login-result';

// Import other files
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  //#region public variables

  redirectUrl: string;

  //#endregion

  //#region private variables

  private _currentUser: User;
  private _token: string;
  private _validFrom: Date;
  private _validTo: Date;

  //#endregion

  //#region Constructor

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  //#endregion

  //#region public methods

  login(username: string, password: string): Observable<LoginResult> {
    const login: UserLogin = {
      username,
      password
    };

    // Make a request to the API to authenticate the user
    return this.http.post<UserLoginResult>(`${this.rootUrl}/authenticate`, login)
     .pipe(
       map(
         ((val: UserLoginResult) => {
           this.CurrentUser = val.user;
           this.Token = val.token;
           this.ValidFrom = val.validFrom;
           this.ValidTo = val.validTo;

           const result: LoginResult = {
            success: true,
            result: val,
            errorMessage: null
           };

           return result;
         })
       ),
       catchError((err: any) => {
        const result: LoginResult = {
          success: false,
          result: err,
          errorMessage: err.error.message
        };
        return of(result);
       })
     );
  }

  logout(): void {
    this.CurrentUser = null;
    this.Token = null;
    this.ValidFrom = null;
    this.ValidTo = null;
    this.router.navigate(['login']);
  }

  refreshToken(): Observable<LoginResult> {
    const login = {
      token: this.Token
    };

    // Make a request to refresh the current token
    return this.http.post<UserLoginResult>(`${this.rootUrl}/refresh`, login).pipe(
      share(),
      map(
        val => {
          this.CurrentUser = val.user;
          this.Token = val.token;
          this.ValidFrom = val.validFrom;
          this.ValidTo = val.validTo;

          const result: LoginResult = {
            success: true,
            result: val,
            errorMessage: null
          };

          return result;
        }
      ),
      catchError((err: any) => {
        const result: LoginResult = {
          success: false,
          result: err,
          errorMessage: err.error.message
        };
        return of(result);
      })
    );
  }

  //#endregion

  //#region Getters and Setters

  get CurrentUser(): User {
    // Return the current user if it's already been set
    if (this._currentUser != null) {
      return this._currentUser;
    }

    // See if there is a current user in local storage
    const storedUserString = localStorage.getItem('current-user');

    // If there is no current user, return null
    if (storedUserString == null || storedUserString === '') {
      return null;
    }

    // Parse the local storage user and return it
    this._currentUser = JSON.parse(storedUserString);
    return this._currentUser;
  }

  set CurrentUser(value: User | null) {
    if (value != null) {
      // Set the current user
      this._currentUser = value;

      // Store it in local storage for the next session
      localStorage.setItem('current-user', JSON.stringify(value));
    } else {
      // Clear the current user
      this._currentUser = null;
      localStorage.removeItem('current-user');
    }
  }

  get isLoggedIn(): boolean {
    // If there is no current user, user isn't signed in.
    if (this.CurrentUser == null) {
      return false;
    } else {
      return true;
    }
  }

  get rootUrl(): string {
    return environment.commonApiRootUrl + '/User';
  }

  get Token(): string {
    if (this._token != null) {
      // If we already have a _token, return it
      return this._token;
    } else {
      // Otherwise, see if it's in local storage
      this._token = localStorage.getItem('user-token');
      return this._token;
    }
  }

  set Token(value: string) {
    if (value != null) {
      // Set the current _token
      this._token = value;

      // Store it in local storage for the next session
      localStorage.setItem('user-token', value);

    } else {
      // Clear the current user
      this._token = null;
      localStorage.removeItem('user-token');
    }
  }

  get ValidFrom(): Date {
    if (this._validFrom != null) {
      return this._validFrom;
    } else {
      this._validFrom = new Date(localStorage.getItem('valid-from'));
      return this._validFrom;
    }
  }

  set ValidFrom(value: Date) {
    if (value != null) {
      this._validFrom = value;
      localStorage.setItem('valid-from', value.toString());

    } else {
      this._validFrom = null;
      localStorage.removeItem('valid-from');
    }
  }

  get ValidTo(): Date {
    if (this._validTo != null) {
      return this._validTo;
    } else {
      this._validTo = new Date(localStorage.getItem('valid-to'));
      return this._validTo;
    }
  }

  set ValidTo(value: Date) {
    if (value != null) {
      this._validTo = value;
      localStorage.setItem('valid-to', value.toString());

    } else {
      this._validTo = null;
      localStorage.removeItem('valid-to');
    }
  }

  //#endregion
}

export class LoginResult {
  success: boolean;
  result: UserLoginResult;
  errorMessage: string;
}
