// Import Angular and related items
import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

// Import components
import { AppComponent } from 'app/app.component';

// Import configurations
import { appConfig } from 'app/app.config';

// Import environment file
import { environment } from '@environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));