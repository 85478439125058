// Import Angular and related items
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, CanActivateFn, CanMatchFn } from '@angular/router';

// Import services
import { AuthService } from '@core/services/auth.service';

export const canActivateAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const url: string = state.url;
  return checkLogin(url);
}

export const canMatchAuthGuard: CanMatchFn = (route: Route) => {
  const url: string = route.path;
  return checkLogin(url);
}

const checkLogin = (url: string): boolean => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.isLoggedIn) {
    return true;
  }

  authService.redirectUrl = url;

  router.navigate(['login']);
  return false;
}