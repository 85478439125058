// Import Angular and related items
import { OverlayModule } from '@angular/cdk/overlay';
import { NgIf } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterOutlet } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Import services
import { AuthService } from '@core/services/auth.service';
import { OverlayService } from '@shared/services/overlay.service';

// Import other files
import { environment } from '@environments/environment';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    // Modules
    MatButtonModule,
    MatIcon,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    OverlayModule,

    // Directives
    NgIf,
    RouterOutlet
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  environmentName = environment.name;
  environmentMessage: string;
  projectVersion = require('../../package.json').version;
  title = 'Goliath';

  private componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    private overlayService: OverlayService,
    private router: Router
  ) { }

  ngOnInit() {
    this.overlayService.currentPortalOverlayClosedStatus.pipe(takeUntil(this.componentDestroyed$)).subscribe(status => {
      if (status === true) {
        this.overlayService.applicationLoaderOverlay.dispose();
      } else {
        return;
      }
    });
    console.log('Is Local Environment: ', environment.name);
    this.environmentMessage = environment.name === 'local' || environment.name === 'dev'
      ? 'Development Environment'
      : environment.name === 'qa'
        ? 'Test Environment' : '';
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  feedback() {
    // Code reference: https://stackoverflow.com/questions/77592088/angular-16-load-a-standalone-component-as-a-dialog-without-routing-or-preloading (Andrei's answer)
    import('./components/feedback/feedback.component').then(result => {
      this.dialog.open(result.FeedbackComponent, {
        width: '30%',
        maxWidth: '40%',
        panelClass: [
          'rounded-dialog-window',
          'padless-dialog'
        ],
        autoFocus: false,
        position: {
          top: '0',
        },
        disableClose: false,
        hasBackdrop: true
      });
    });
  }

  goHome() {
    this.router.navigate(['home']);
  }

  openApplicationAreaOverlay() {
    this.overlayService.openOverlay();
  }

  openChangePasswordDialog() {
    // Code reference: https://stackoverflow.com/questions/77592088/angular-16-load-a-standalone-component-as-a-dialog-without-routing-or-preloading (Andrei's answer)
    import('./components/change-password/change-password.component').then(result => {
      const dialogRef = this.dialog.open(result.ChangePasswordComponent, {
        width: '30%',
        maxWidth: '40%',
        panelClass: [
          'rounded-dialog-window',
          'padless-dialog'
        ],
        data: {
          userId: this.authService.CurrentUser.userId
        },
        autoFocus: false,
        position: {
          top: '0',
        },
        disableClose: true,
        hasBackdrop: true
      });
      dialogRef.afterClosed().pipe(takeUntil(this.componentDestroyed$)).subscribe();
    });
  }

  signOut(): void {
    this.authService.logout();
    this.router.navigate(['login']);
  }
}